import { User } from '../../auth/user';

export interface MessengerPositionParams {
  zIndex?: number;
  padding?: {
    inline?: number;
    block?: number;
  };
}

export abstract class MessengerClientService {
  abstract initialize(): void;
  abstract reset(): void;
  abstract shutdown(): void;
  abstract updatePosition(params: MessengerPositionParams): void;
  abstract updateUser(user: User): void;
}
